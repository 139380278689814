import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Column from "../components/Sections/TwoColumn"
import Subscription from "../components/Sections/Subscription"
import SocialFeed from "../components/Sections/SocialFeed"
import { AboutImages } from "../Hooks/AboutImages"

const About = () => {
  const { myStory, blog, mission } = AboutImages()
  return (
    <Layout>
      <SEO
        title="About Me"
        description="All about Katelyn Fedrick. Read about my story, how I started my blog and about my mission, Keeping All Troops Encouraged (K.A.T.E.)"
      />
      <Column
        fluid={myStory.fluid}
        reverse={true}
        alt="Portrait of Katelyn Fedrick"
        loading={"eager"}
        fadeIn={false}
        subhead="Meet Katelyn"
        head="My Story"
        body={`
                Hi! My name is Katelyn and I am so happy you are here. When I was about
                eight years old I entered into my very first pageant. From then on I fell in love
                with pageants and all things modeling. Currently, I hold the title of Miss
                Tennessee Valley Fair, where I will be representing my fair at the Miss
                Tennessee Fairest of the Fair pageant on January 20. Competing in pageants
                has inspired my love for fashion, beauty, adventures, and all things lifestyle
                and fitness. Not only have pageants shaped me into the person I am today
                but they have also taught me how to reach for my goals and never hold back.
                Currently, I am a sophomore at the University of Tennessee and I am pursuing
                a BSBA: marketing degree. After college I plan to use my degree to open up
                my own business. I plan to own and market a Pageantry and Couture fashion
                boutique to help other girls prepare for their special events and competitions.
            `}
      />
      <Column
        fluid={blog.fluid}
        subhead="My Blog"
        alt="Portrait of Katelyn Fedrick in a blue dress"
        link="/blog/"
        buttonText="Visit My Blog"
        head="Sharing My Stories"
        body={
          `With my new blog, I plan to take you along on a variety of my adventures and show you what I have learned along the way. My goal is to connect with you through current fashion and beauty trends, give you the most delicious recipes, and encourage you to maintain a healthy lifestyle. Do not forget to follow me on each of my social media platforms and subscribe for additional content. :)

      `}
      />
      <Column
        fluid={mission.fluid}
        reverse={true}
        alt="Portrait of Katelyn Fedrick"
        link="/mission/"
        buttonText="Find Out More"
        subhead="My Mission"
        head="Keeping All Troops Encouraged (K.A.T.E.)"
        body={`
                Coming from a military-based family has inspired me to give back to as many
                soldiers and veterans as possible. I have had an up close and personal view of
                what it is like for many of our soldiers. As a result, I created K.A.T.E to give back
                to our soldiers and veterans and not let any of them be forgotten. With this
                platform, I have made and collected over 10,000 thank you cards that have
                been distributed to soldiers and veteran nursing homes. An additional way I
                give back is by collecting items for care packages (full list linked on the
                mission page). In each care package, a handwritten thank you card showing
                my gratitude for their sacrifice and commitment to this country is included.
                Although these acts may seem small, they truly make a difference in each
                soldier’s life. When I created this platform, I had hoped that it would
                eventually grow larger and I could get others my age involved. Thankfully, I
                was able to extend my platform across my county and involve my local teen
                board full of students who donated items for care packages and thank you
                cards. This truly meant the world to me and I am so excited to extend it even
                further in the future.      
              `}
      />
      <Subscription
        header={
          <React.Fragment>
            <b>Thanks for Stopping By!</b>
          </React.Fragment>
        }
        body="Be the first to know about new blog posts and get the latest updates about my mission project."
      />
      <SocialFeed />
    </Layout>
  )
}

export default About
