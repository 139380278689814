import { useStaticQuery, graphql } from "gatsby"

export const AboutImages = () => {
  const { contentfulBlogImages } = useStaticQuery(
    graphql`
      query {
        contentfulBlogImages {
          myStory {
            title
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          blog {
            title
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mission {
            title
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    `
  )
  return contentfulBlogImages
}
